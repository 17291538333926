<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Campañas Activas</h3>
            </div>
          </div>
          <div class="card-body p-5 pb-7">
            <v-data-table
              v-model="campaignsSelected"
              @input="initJobsMap()"
              :headers="headersCampaigns"
              :items="campaigns"
              :sort-by="['fechaCreacion']"
              :sort-desc="true"
              class="elevation-1"
              show-select
              item-key="campaignID"
            >
              <template v-slot:[`item.nombre`]="{ item }">
                <span
                  class="cursor-pointer"
                  @click="redirectTracing(item.mandanteNombre, item.campaignID)"
                >
                  {{ item.nombre }}
                </span>
              </template>
              <template v-slot:[`item.diasTotales`]="{ item }">
                {{ item.diasTotales }} / {{ item.diasTranscurridos }} /
                {{ item.diasTotales - item.diasTranscurridos }}
              </template>
              <template v-slot:[`item.cantidadTareas`]="{ item }">
                {{ item.cantidadTareas }} / {{ item.cantidadTareasAsignadas }} /
                {{ item.cantidadTareasRealizadas }}
              </template>
              <template v-slot:[`item.cantidadTareasRealizadas`]="{ item }">
                <b-progress
                  class="mt-2"
                  height="1.5rem"
                  :max="
                    item.cantidadTareasAsignadas > 0
                      ? item.cantidadTareasAsignadas
                      : item.cantidadTareas
                  "
                  show-value
                >
                  <b-progress-bar
                    :value="item.cantidadTareasRealizadas"
                    variant="success"
                    v-if="item.cantidadTareasAsignadas > 0"
                  >
                    <span>
                      {{ item.cantidadTareasRealizadas }} ({{
                        Math.round(
                          (item.cantidadTareasRealizadas /
                            item.cantidadTareasAsignadas) *
                            100
                        )
                      }}%)
                    </span>
                  </b-progress-bar>
                  <b-progress-bar
                    :value="
                      item.cantidadTareasAsignadas -
                      item.cantidadTareasRealizadas
                    "
                    variant="primary"
                    v-if="item.cantidadTareasAsignadas > 0"
                  >
                    <span>
                      {{
                        item.cantidadTareasAsignadas -
                        item.cantidadTareasRealizadas
                      }}
                      ({{
                        Math.round(
                          ((item.cantidadTareasAsignadas -
                            item.cantidadTareasRealizadas) /
                            item.cantidadTareasAsignadas) *
                            100
                        )
                      }}%)
                    </span>
                  </b-progress-bar>
                  <b-progress-bar
                    :value="item.cantidadTareas"
                    variant="primary"
                    v-if="item.cantidadTareasAsignadas === 0"
                  >
                    <span> {{ item.cantidadTareas }} (100%) </span>
                  </b-progress-bar>
                </b-progress>
              </template>
              <template v-slot:[`item.diasTranscurridos`]="{ item }">
                <b-progress
                  class="mt-2"
                  height="1.5rem"
                  :max="item.diasTotales"
                  show-value
                >
                  <b-progress-bar
                    :value="item.diasTranscurridos"
                    variant="success"
                  >
                    <span>
                      {{ item.diasTranscurridos }} ({{
                        Math.round(
                          (item.diasTranscurridos / item.diasTotales) * 100
                        )
                      }}%)
                    </span>
                  </b-progress-bar>
                  <b-progress-bar
                    :value="item.diasTotales - item.diasTranscurridos"
                    variant="primary"
                  >
                    <span>
                      {{ item.diasTotales - item.diasTranscurridos }} ({{
                        Math.round(
                          ((item.diasTotales - item.diasTranscurridos) /
                            item.diasTotales) *
                            100
                        )
                      }}%)
                    </span>
                  </b-progress-bar>
                </b-progress>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Ubicación</h3>
            </div>
          </div>
          <div class="card-body p-5">
            <div>
              <TaskMap ref="task_map" :aJobs="aJobs" :bCircle="true" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { GET_CAMPAIGNS_MANDANTE } from "@/core/services/store/campaign.module";
import { GET_TASK_CAMPAIGN } from "@/core/services/store/task.module";
import { mapGetters } from "vuex";
import TaskMap from "../components/task/TaskMap.vue";

export default {
  name: "dashboard",
  components: {
    TaskMap,
  },
  data() {
    return {
      campaignsSelected: [],
      headersCampaigns: [
        {
          text: "Nombre",
          value: "nombre",
          width: "10em",
        },
        {
          text: "Tipo",
          value: "tipo",
          width: "9em",
          fixed: true,
        },
        {
          text: "Jobbers",
          value: "jobbers",
          width: "4em",
          align: "center",
          fixed: true,
          sortable: false,
        },
        {
          text: "F. Termino",
          value: "fechaTermino",
          width: "8em",
          fixed: true,
          sortable: false,
        },
        {
          text: "Dias",
          value: "diasTotales",
          width: "10em",
          fixed: true,
          sortable: false,
        },
        {
          text: "Transcurridos",
          value: "diasTranscurridos",
          width: "10em",
          fixed: true,
          sortable: false,
        },
        {
          text: "Tareas",
          value: "cantidadTareas",
          width: "10em",
          fixed: true,
          sortable: false,
        },
        {
          text: "Realizadas",
          value: "cantidadTareasRealizadas",
          width: "10em",
          fixed: true,
          sortable: false,
        },
      ],
      campaigns: [],
      aJobs: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  created() {
    this.getCampaigns();
  },
  methods: {
    getCampaigns() {
      KTUtil.setLoading();
      let oVue = this;
      let params = {
        mandante:
          oVue.currentUserPersonalInfo.mandanteID !== "null"
            ? oVue.currentUserPersonalInfo.mandanteID
            : null,
        activas: 1,
      };
      oVue.campaigns = [];
      oVue.$store.dispatch(GET_CAMPAIGNS_MANDANTE, params).then((campaigns) => {
        oVue.campaigns = campaigns;
        KTUtil.removeLoading();
        oVue.setTasks();
      });
    },
    async getTasksByCampaign(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllJobsDataResponse = await oVue.$store.dispatch(
        GET_TASK_CAMPAIGN,
        iCampaignID
      );
      KTUtil.removeLoading();

      return aAllJobsDataResponse;
    },
    setTasks() {
      let oVue = this;
      oVue.campaigns.forEach(async (c, i) => {
        const aAllTaskDataResponse = await oVue.getTasksByCampaign(
          c.campaignID
        );
        const taskData = aAllTaskDataResponse;
        const campaignIndex = oVue.campaigns.findIndex(
          (x) => x.campaignID === c.campaignID
        );
        oVue.campaigns[campaignIndex].tasks = taskData;
        if (i === oVue.campaigns.length - 1) {
          setTimeout(() => {
            oVue.initJobsMap();
          }, 500);
        }
      });
    },
    initJobsMap() {
      let oVue = this;
      let aJobs = [];
      oVue.campaignsSelected.forEach((c) => {
        if (c.tasks) {
          c.tasks.forEach((t) => {
            aJobs.push(t);
          });
        }
      });
      oVue.aJobs = aJobs;
      oVue.$refs.task_map.initJobsMap();
    },
    redirectTracing(mandante, campaign) {
      let oVue = this;
      let params = {
        mandante,
        campaign,
      };
      oVue.$router.push({ name: "tracing", params });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>
